<template>
  <div>
    <Table
      :headings="tableHeadings"
      :data="users"
      :loading="isLoading"
      @actionView="actionView"
      @actionDelete="actionDelete"
    >
    </Table>
    <v-pagination
        v-model="pageNumber"
        :pages="meta.last_page"
        @update:modelValue="updateHandler"
    >
    </v-pagination>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Table from '@/components/table/Table.vue';
import { Paginator } from "@/types/Paginator";
import { User } from "@/types/User";
import { paginateUsers } from "@/api/users.api";
import { spinnerIsLoading } from "@/mixins/spinner.mixin";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import "@/assets/css/paginator.css";
import { mapGetters } from "vuex";

export default defineComponent({
  name: "Users",
  mixins: [spinnerIsLoading],
  components: {
    Table,
    VPagination,
  },
  data() {
    return {
      tableHeadings: {
        id: '#',
        name: 'Nome',
        surname: 'Cognome',
        email: 'email',
        email_verified_at: 'Verificata il',
        created_at: 'Iscritto il'
      },
      meta: {} as Paginator,
      users: [] as User[],
      pageNumber: 1 as number,
    }
  },
  methods: {
    async getUsers(pageNumber: number) {
      this.startLoading();

      const response: any = await paginateUsers(pageNumber);

      this.users = response.data;
      this.meta = response.meta;

      this.stopLoading();
    },
    updateHandler(page: number) {
      this.pageNumber = page;

      this.getUsers(page);
    },
    actionView(id: number) {
      this.$router.push({ name: 'UserProfile', params: { id } });
    },
    actionDelete() {

    }
  },
  computed: {
    ...mapGetters([
      'user',
    ])
  },
  mounted() {
    if (!this.user.is_admin) {
      return this.$router.push({ name: 'Dashboard' });
    }

    this.getUsers(this.pageNumber);
  }
})
</script>

<style scoped>

</style>